import React, { ReactElement } from 'react';
import Header from './Header';
import InfoBanner from './InfoBanner';
import { ToastContainer } from 'react-toastify';
import infoIcon from '../icons/info.svg';

const Layout = ({ children }: any): ReactElement => {
  return (
    <>
      <Header />
      <main>
        {children}
        <ToastContainer
          position="bottom-right"
          className={'toast'}
          autoClose={2000}
          icon={<img width={20} src={infoIcon} />}
        />
      </main>
      <footer>
        <InfoBanner />
      </footer>
    </>
  );
};

export default Layout;
