import {
  Box,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { DisplayLightClientUpdate } from '../../interface';
import { shorten } from '../../utils';

interface ILightClientUpdates {
  updates: DisplayLightClientUpdate[];
  error: boolean;
}

const LightClientUpdates = ({ updates, error }: ILightClientUpdates) => {
  return (
    <TableContainer component={Paper} sx={{ background: 'transparent', marginBottom: '24px' }}>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: '#91AFCC', borderBottom: `1px solid #0384FD` }}>
              Rollup
            </TableCell>
            <TableCell sx={{ color: '#91AFCC', borderBottom: `1px solid #0384FD` }} align="left">
              Rollup Tx Hash
            </TableCell>
            <TableCell sx={{ color: '#91AFCC', borderBottom: `1px solid #0384FD` }} align="right">
              L1 Block Number
            </TableCell>
          </TableRow>
        </TableHead>
        {error ? (
          <Box margin={'20px'} textAlign={'center'}>
            <p className="red"> Something went wrong while fetching the updates...</p>
          </Box>
        ) : (
          <TableBody>
            {updates.map(update => {
              return (
                <TableRow
                  key={update.rollupTxHash}
                  sx={{
                    height: '60px',
                  }}
                >
                  <TableCell
                    sx={{ color: '#F3F9FF', borderBottom: '1px solid #182027' }}
                    align="right"
                  >
                    <Box display={'flex'} alignItems={'center'}>
                      <img src={update.logo} alt={'Chain logo'} />
                      <Box marginLeft={'10px'}> {update.rollup}</Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{ color: '#F3F9FF', borderBottom: '1px solid #182027' }}
                    align="left"
                  >
                    <Link
                      href={`${update.explorerUrl}/tx/${update.rollupTxHash}`}
                      target={'_blank'}
                      sx={{ textDecoration: 'none', color: '#0384FD' }}
                    >
                      {shorten(update.rollupTxHash)}
                    </Link>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: '#F3F9FF',
                      borderBottom: '1px solid #182027',
                    }}
                    align="right"
                  >
                    {update.l1BlockNumber}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default LightClientUpdates;
