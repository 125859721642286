import optimismLogo from '../icons/optimism.svg';
import baseLogo from '../icons/base.svg';
import { Network } from '../interface';
import moment from 'moment';

export const OPTIMISM_RPC_URL = 'https://goerli.optimism.io';
export const BASE_RPC_URL = 'https://goerli.base.org';
export const OPTIMISM_EXPLORER = 'https://goerli-optimism.etherscan.io';
export const BASE_EXPLORER = 'https://goerli.basescan.org';
export const WISP_DOCS = 'https://docs.wispprotocol.com/';
export const WISP_WEBSITE = 'https://www.wispprotocol.com/';
export const WISP_FAQ = 'https://docs.wispprotocol.com/demo-app/demo-bridge#faq';
export const GOERLI_EXPLORER = 'https://goerli.etherscan.io/';
export const BLOG_POST = 'https://limelabs.tech/blog/publications/wisp-alpha-takeaways';

export const NETWORKS: Network[] = [
  {
    chainId: 420,
    name: 'Optimism',
    icon: optimismLogo,
    explorerUrl: OPTIMISM_EXPLORER,
    bridgeContract: process.env.REACT_APP_BRIDGE_OPTIMISM || '',
  },
  {
    chainId: 84531,
    name: 'Base',
    icon: baseLogo,
    explorerUrl: BASE_EXPLORER,
    bridgeContract: process.env.REACT_APP_BRIDGE_BASE || '',
  },
];

// Constants
export const API_ROOT_URL = 'https://crc-demo-12966.uc.r.appspot.com/api/v1';
export const AMOUNT_MAX_LENGTH = 10;
export const MAX_AMOUNT = 0.01;

// Error Message
export const PLEASE_INSTALL_METAMASK = 'Please install MetaMask';
export const PLEASE_ADD_NETWORKS = 'Please add Optimism Goerli and Base Goerli to Metamask';
export const USER_DENIED_TX_SIGNATURE = 'User denied transaction signature';
export const INSUFFICIENT_BALANCE = 'Insufficient balance';
export const ACTION_REJECTED = 'ACTION_REJECTED';
export const MAX_AMOUNT_ERROR = `The maximum amount to deposit is ${MAX_AMOUNT}`;

// Helpers
export const shorten = (address: string = '') =>
  `${address?.substring(0, 6)}...${address?.substring(address?.length - 4, address?.length)}`;

export const shorten2 = (address: string = '') =>
  `${address?.substring(0, 4)}...${address?.substring(address?.length - 4, address?.length)}`;

export const formatStr = (str: string) => str.substring(0, 6);

export enum TX_STATUS {
  Loading = 1,
  Confirmed,
  Success,
}

export const MSG_STATUS = {
  delivered: 'Delivered',
  inTransit: 'In transit',
  sent: 'Sent',
};

export const AMOUNT_REGEX = /^[0-9]*\.?[0-9]*$/;

//Set status border color
export const setBorder = (status: string) => {
  let statusBorderColor = '';
  if (status === MSG_STATUS.delivered) {
    statusBorderColor = '#41C587';
  } else if (status === MSG_STATUS.inTransit) {
    statusBorderColor = '#FFE480';
  } else {
    statusBorderColor = '#0040FF';
  }
  return statusBorderColor;
};

//Calculate "Delivery Duration"
export const calculateDeliveryDuration = (
  status: string,
  targetChainTxTimestamp: number,
  sourceChainTxTimestamp: number,
) => {
  let deliveryDuration = '-';
  if (status === MSG_STATUS.delivered && targetChainTxTimestamp) {
    const duration = targetChainTxTimestamp - sourceChainTxTimestamp;
    deliveryDuration = moment.duration(duration, 'seconds').humanize();
  }
  return deliveryDuration;
};

//Determine "Status"
export const setStatus = (targetChainTxHash: string, l1BlockNumber: number) => {
  let status = '';
  if (targetChainTxHash) {
    status = MSG_STATUS.delivered;
  } else {
    if (l1BlockNumber) {
      status = MSG_STATUS.inTransit;
    } else {
      status = MSG_STATUS.sent;
    }
  }
  return status;
};
