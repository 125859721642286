import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { TX_STATUS } from '../../utils';
import successIcon from '../../icons/success.svg';
import explorerLinkIcon from '../../icons/explorer-link.svg';
import { Link } from 'react-router-dom';

export interface ITransactionModal {
  open: boolean;
  onClose: any;
  txStatus: number;
  txHash: string;
  explorerUrl: string;
}

const Transaction = (props: ITransactionModal) => {
  const { onClose, open, txStatus, txHash } = props;

  const renderTitle = () => {
    let title = '';
    if (txStatus === TX_STATUS.Loading) {
      title = 'Waiting For Confirmation';
    } else if (txStatus === TX_STATUS.Confirmed) {
      title = 'Processing Transaction...';
    } else if (txStatus === TX_STATUS.Success) {
      title = 'Successful Transaction';
    }
    return title;
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        style: {
          width: '512px',
          height: '300px',
          backgroundColor: '#071C58',
          boxShadow: 'none',
          borderRadius: '16px',
        },
      }}
      sx={{
        backdropFilter: 'blur(8px)',
      }}
    >
      <Box padding={'24px'}>
        <Box margin={'30px auto'} textAlign={'center'}>
          {txStatus === TX_STATUS.Success ? (
            <Box>
              <img width={100} src={successIcon} alt="Success icon" />
            </Box>
          ) : (
            <CircularProgress thickness={3} size={100} />
          )}
        </Box>
        <Box marginBottom={'5px'} textAlign={'center'}>
          <h2 className="modal-title">{renderTitle()}</h2>
        </Box>
        {txStatus === TX_STATUS.Loading ? (
          <p className="steel-gray align-center"> Confirm this transaction in your wallet</p>
        ) : null}
        {txStatus === TX_STATUS.Success && (
          <Box>
            <Link to={`message/${txHash}`} style={{ textAlign: 'center', textDecoration: 'none' }}>
              <Box display={'flex'} justifyContent={'center'}>
                <img src={explorerLinkIcon} alt={'Explorer link'} />
                <p className="white pointer">View message</p>
              </Box>
            </Link>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default Transaction;
