import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../provider';
import { ethers } from 'ethers';
import {
  ACTION_REJECTED,
  AMOUNT_MAX_LENGTH,
  AMOUNT_REGEX,
  BLOG_POST,
  formatStr,
  INSUFFICIENT_BALANCE,
  MAX_AMOUNT,
  MAX_AMOUNT_ERROR,
  NETWORKS,
  TX_STATUS,
  USER_DENIED_TX_SIGNATURE,
} from '../utils';
import BigNumber from 'bignumber.js';
import Swap from '../icons/swap.svg';
import Eth from '../icons/eth.png';
import Transaction from '../components/Modals/Transaction';
import TransactionConfirmationModal from '../components/Modals/TransactionConfirmation';
import WrongNetwork from '../components/Modals/WrongNetwork';
import { useAccount, useBalance, useNetwork, useSigner, useSwitchNetwork } from 'wagmi';
import BridgeContract from '../contracts/Bridge.json';
import { useContract } from 'wagmi';
import { Link } from 'react-router-dom';

const Bridge = () => {
  const {
    destinationNetwork,
    setDestinationNetwork,
    setSourceNetwork,
    sourceNetwork,
    wrongNetworkModal,
    showWrongNetworkModal,
  } = useContext(AppContext);

  const { isConnected, address } = useAccount();
  const { data } = useBalance({
    address: address,
  });
  const { data: signer } = useSigner();
  const { switchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();

  const [asset, setAsset] = useState('ETH');
  const [confirmationModal, showConfirmationModal] = useState(false);
  const [txModal, showTxModal] = useState(false);
  const [txStatus, setTxStatus] = useState(TX_STATUS.Loading);
  const [txHash, setTxHash] = useState('');
  const [amount, setAmount] = useState('0.00001');
  const [bridgeError, setBridgeError] = useState('');

  useEffect(() => {
    handleAmountError();
  }, []);

  useEffect(() => {
    showWrongNetworkModal(false);
  }, [chain]);

  useEffect(() => {
    handleAmountError();
  }, [amount, data?.formatted]);

  // Reset txStatus and txHash state when the user closes the txModal
  useEffect(() => {
    if (!txModal) {
      setTxStatus(TX_STATUS.Loading);
      setTxHash('');
    }
  }, [txModal]);

  // Contracts
  const optimismBridge = useContract({
    address: process.env.REACT_APP_BRIDGE_OPTIMISM || '',
    abi: BridgeContract.abi,
    signerOrProvider: signer,
  });

  const baseBridge = useContract({
    address: process.env.REACT_APP_BRIDGE_BASE || '',
    abi: BridgeContract.abi,
    signerOrProvider: signer,
  });

  const handleAmountError = () => {
    if (data?.formatted) {
      // Check if the desired amount is not higher than the user balance or the max allowed deposit
      const amountBN = new BigNumber(amount);
      const balanceBN = new BigNumber(data.formatted);
      const insufficientBalance = amountBN.isGreaterThan(balanceBN);
      if (parseFloat(amount) > MAX_AMOUNT) {
        setBridgeError(MAX_AMOUNT_ERROR);
      } else if (insufficientBalance) {
        setBridgeError(INSUFFICIENT_BALANCE);
      } else {
        setBridgeError('');
      }
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBridgeError('');
    const { value } = e.target;
    if (AMOUNT_REGEX.test(value) && value.length < AMOUNT_MAX_LENGTH) {
      setAmount(value);
    }
  };

  const handleMaxAmount = () => {
    if (data?.formatted) {
      setBridgeError('');
      setAmount(formatStr(data.formatted));
    }
  };

  const handleDeposit = async () => {
    if (sourceNetwork) {
      setBridgeError('');
      showConfirmationModal(false);
      showTxModal(true);

      if (sourceNetwork.chainId) {
        const sourceBridge = sourceNetwork.chainId === 420 ? optimismBridge : baseBridge;
        const generateNonce = () => parseInt(String(Date.now() * Math.random()));

        try {
          //@ts-ignore
          const tx = await sourceBridge.lock(generateNonce(), {
            value: ethers.utils.parseUnits(amount, 'ether'),
          });

          setTxStatus(TX_STATUS.Confirmed);
          const receipt = await tx.wait();
          if (receipt.status === 1) {
            setTxStatus(TX_STATUS.Success);
            console.log(receipt);
            setTxHash(receipt.logs[receipt.logs.length - 1].data);
          }
        } catch (error) {
          //@ts-ignore
          if (error.code === ACTION_REJECTED) {
            setBridgeError(USER_DENIED_TX_SIGNATURE);
            showTxModal(false);
          }
          console.log(error);
        }
      }
    }
  };

  const handleChangeSource = (e: SelectChangeEvent<string>) => {
    setBridgeError('');
    setSourceNetwork(NETWORKS.find(n => n.name === e.target.value) || NETWORKS[0]);
  };

  const handleChangeDestination = (e: SelectChangeEvent<string>) => {
    setBridgeError('');
    setDestinationNetwork(NETWORKS.find(n => n.name === e.target.value) || NETWORKS[0]);
  };

  const handleSwapNetworks = () => {
    if (sourceNetwork && destinationNetwork) {
      setBridgeError('');
      setSourceNetwork({ ...destinationNetwork });
      setDestinationNetwork({ ...sourceNetwork });
    }
  };

  const handleChangeAsset = (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    setAsset(value);
  };

  const handleConfirmTx = async () => {
    const networkIsSource = chain?.id === sourceNetwork.chainId;
    if (!networkIsSource) {
      showWrongNetworkModal(true);
      return;
    }
    showConfirmationModal(true);
  };

  const cannotDeposit =
    parseFloat(amount) > MAX_AMOUNT ||
    sourceNetwork?.name === destinationNetwork?.name ||
    !sourceNetwork?.chainId ||
    !destinationNetwork?.chainId ||
    !amount ||
    bridgeError === MAX_AMOUNT.toString() ||
    bridgeError === INSUFFICIENT_BALANCE ||
    amount[amount.length - 1] === '0' ||
    amount[amount.length - 1] === '.';

  return (
    <>
      <WrongNetwork
        open={wrongNetworkModal}
        onClose={() => showWrongNetworkModal(false)}
        switchNetwork={() => switchNetwork?.(sourceNetwork.chainId)}
      />
      {sourceNetwork.chainId ? (
        <TransactionConfirmationModal
          open={confirmationModal}
          onClose={() => showConfirmationModal(false)}
          sourceNetwork={sourceNetwork}
          destinationNetwork={destinationNetwork}
          amount={amount}
          handleDeposit={handleDeposit}
        />
      ) : null}
      {sourceNetwork.chainId ? (
        <Transaction
          open={txModal}
          onClose={() => showTxModal(false)}
          txStatus={txStatus}
          txHash={txHash}
          explorerUrl={''}
        />
      ) : null}
      <Box width={'608px'} height={'455px'} margin={'0 auto'}>
        <Box display={'flex'} className={'steel-gray'}>
          <p>The Wisp alpha testnet is now closed.</p>{' '}
          <Link className="ml-13 steel-gray" to={BLOG_POST} target="_blank">
            Read more about it here.
          </Link>
        </Box>
        <h2 className="page-title">Bridge</h2>
        <Box
          margin={'0 auto'}
          padding={'25px'}
          borderRadius={'24px'}
          boxShadow={'0px 0px 32px rgba(1, 6, 19, 0.32)'}
          sx={{
            background: '#061541',
          }}
        >
          <Box marginBottom={'8px'}>
            <p className="steel-gray weight-600 f-14">Send</p>
          </Box>
          <Grid container spacing={2} marginBottom={'29px'}>
            <Grid item xs={8}>
              <Box position={'relative'}>
                <TextField
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: '12px 20px',
                    },
                  }}
                  variant="standard"
                  disabled={!data?.formatted}
                  onChange={handleAmountChange}
                  sx={{
                    marginRight: '20px',
                    width: '100%',
                    backgroundColor: '#010613',
                    borderRadius: '56px',
                    outline: 'none',
                    height: '56px',
                    '& input, label': {
                      color: '#F3F9FF',
                    },
                    '& label.Mui-focused': {
                      color: '#F3F9FF',
                      backgroundColor: 'red',
                    },
                    '&.Mui-focused': {
                      background: 'red',
                    },
                  }}
                  placeholder="Enter amount"
                  value={amount}
                >
                  {amount}
                </TextField>
                {bridgeError && <p className="error"> {bridgeError}</p>}
                <Box
                  component="span"
                  fontSize={'14px'}
                  position={'absolute'}
                  top={'7px'}
                  right={'20px'}
                >
                  {isConnected && data && (
                    <>
                      <p className="f-14 steel-gray"> Balance {formatStr(data.formatted)}</p>
                      <p
                        onClick={handleMaxAmount}
                        className="pointer light-blue align-right bold f-16"
                      >
                        MAX
                      </p>
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Select
                id={'source'}
                MenuProps={{
                  sx: {
                    '&& .Mui-selected': {
                      backgroundColor: 'transparent',
                    },
                    '&& .Mui-selected:hover': {
                      backgroundColor: '#0D256C',
                      borderRadius: '16px',
                    },
                  },
                  PaperProps: {
                    sx: {
                      marginTop: 0.5,
                      color: '#F3F9FF',
                      background: '#010613',
                      border: `1px solid '#010613'`,
                      borderRadius: '16px',
                      '& .MuiMenuItem-root': {
                        padding: 1.5,
                      },
                    },
                  },
                }}
                sx={{
                  color: '#F3F9FF',
                  background: '#010613',
                  borderRadius: '56px',
                  width: '100%',
                  height: '56px',
                  '.MuiSvgIcon-root ': {
                    fill: '#F3F9FF',
                  },
                }}
                value={asset}
                onChange={handleChangeAsset}
              >
                <MenuItem value={asset}>
                  <Box display={'flex'} alignItems={'center'}>
                    <img width={32} src={Eth} alt="ETH" />
                    <p className="ml-13">ETH</p>
                  </Box>
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Box marginBottom={'8px'}>
            <p className="steel-gray weight-600 f-14">From</p>
          </Box>
          <Box>
            <FormControl sx={{ width: '100%' }}>
              <Select
                disabled={!isConnected}
                MenuProps={{
                  sx: {
                    '&& .Mui-selected': {
                      backgroundColor: 'transparent',
                    },
                    '&& .Mui-selected:hover': {
                      backgroundColor: '#0D256C',
                      borderRadius: '16px',
                    },
                  },
                  PaperProps: {
                    sx: {
                      color: '#F3F9FF',
                      background: '#010613',
                      borderRadius: '16px',
                      '& .MuiMenuItem-root': {
                        padding: 2,
                      },
                    },
                  },
                }}
                sx={{
                  color: '#F3F9FF',
                  height: '56px',
                  background: '#010613',
                  borderRadius: '56px',
                  '.MuiSvgIcon-root ': {
                    fill: '#F3F9FF',
                  },
                }}
                value={sourceNetwork.name || ''}
                onChange={handleChangeSource}
              >
                {NETWORKS.map(network => {
                  return (
                    <MenuItem key={network.name} value={network.name}>
                      <Box display={'flex'} alignItems={'center'}>
                        {network.icon && <img src={network.icon} alt="Network logo" />}
                        <p className="ml-13">{network.name}</p>
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box
            onClick={handleSwapNetworks}
            textAlign={'center'}
            marginTop={'16px'}
            marginBottom={'-16px'}
            sx={{
              cursor: 'pointer',
            }}
          >
            <img src={Swap} alt="Swap" />
          </Box>
          <Box marginBottom={'8px'}>
            <p className="steel-gray weight-600 f-14">To</p>
          </Box>
          <Box>
            <FormControl sx={{ width: '100%' }}>
              <Select
                id={'destination'}
                disabled={!isConnected}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      color: '#F3F9FF',
                      background: '#010613',
                      borderRadius: '16px',
                      '& .MuiMenuItem-root': {
                        padding: 2,
                      },
                    },
                  },
                }}
                sx={{
                  color: '#F3F9FF',
                  height: '56px',
                  background: '#010613',
                  borderRadius: '56px',
                  '.MuiSvgIcon-root ': {
                    fill: '#F3F9FF',
                  },
                }}
                value={destinationNetwork.name || ''}
                onChange={handleChangeDestination}
              >
                {NETWORKS.map(network => {
                  return (
                    <MenuItem
                      disabled={sourceNetwork.chainId === network.chainId}
                      key={network.name}
                      value={network.name}
                    >
                      <Box display={'flex'} alignItems={'center'}>
                        {network.icon && <img src={network.icon} alt="Network logo" />}
                        <p className="ml-13">{network.name}</p>
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Button
            disabled={cannotDeposit}
            fullWidth
            sx={{
              height: '56px',
              marginTop: '48px',
              '&.Mui-disabled': {
                background: '#071C58',
                color: '#91AFCC',
              },
              borderRadius: '100px',
              fontWeight: 'bold',
            }}
            onClick={handleConfirmTx}
            variant="contained"
          >
            Confirm transaction
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Bridge;
