import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { DisplayMessage } from '../../interface';
import { MSG_STATUS, setBorder, shorten } from '../../utils';
import arrowIcon from '../../icons/arrow.svg';

interface IMessages {
  messages: DisplayMessage[];
  error: boolean;
}

const Messages = ({ messages, error }: IMessages) => {
  return (
    <TableContainer component={Paper} sx={{ background: 'transparent', marginBottom: '24px' }}>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow>
            <Tooltip title={'The unique identifier of the message'}>
              <TableCell sx={{ color: '#91AFCC', borderBottom: `1px solid '#010613'` }}>
                Message ID
              </TableCell>
            </Tooltip>
            <Tooltip title={'Where from and where to is this message sent'}>
              <TableCell
                sx={{ color: '#91AFCC', borderBottom: `1px solid '#010613'` }}
                align="right"
              >
                Source & Destination
              </TableCell>
            </Tooltip>
            <Tooltip title={'When was this message initiated'}>
              <TableCell
                sx={{ color: '#91AFCC', borderBottom: `1px solid '#010613'` }}
                align="right"
              >
                Sent At
              </TableCell>
            </Tooltip>
            <Tooltip title={'How long did it take from initiation to delivery'}>
              <TableCell
                sx={{ color: '#91AFCC', borderBottom: `1px solid '#010613'}` }}
                align="right"
              >
                Delivery Duration
              </TableCell>
            </Tooltip>
            <TableCell
              sx={{ color: '#91AFCC', borderBottom: `1px solid '#010613'}` }}
              align="right"
            >
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        {error ? (
          <Box margin={'20px'} textAlign={'center'}>
            <p className="red">Something went wrong while fetching the messages...</p>
          </Box>
        ) : (
          <TableBody>
            {messages.map((message: DisplayMessage) => {
              const statusBorderColor = setBorder(message?.status || '');

              let statusTooltip = '';
              if (message.status === MSG_STATUS.sent) {
                statusTooltip = 'The message has been dispatched from the source rollup';
              } else if (message.status === MSG_STATUS.inTransit) {
                statusTooltip = 'The message has reached L1';
              } else if (message.status === MSG_STATUS.delivered) {
                statusTooltip = 'The message has been delivered to the destination rollup';
              }

              return (
                <TableRow
                  key={message.messageId}
                  sx={{
                    height: '60px',
                  }}
                >
                  <TableCell
                    sx={{ color: '#F3F9FF', borderBottom: '1px solid #182027' }}
                    component="th"
                    scope="row"
                  >
                    <Link
                      to={`/message/${message.messageId}`}
                      style={{ textDecoration: 'none', color: '#0384FD' }}
                    >
                      {shorten(message.messageId)}
                    </Link>
                  </TableCell>
                  <TableCell
                    sx={{ color: '#F3F9FF', borderBottom: '1px solid #182027' }}
                    align="right"
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'right'}
                      alignItems={'center'}
                      gap={'7px'}
                    >
                      <Box>{message.source}</Box>
                      <img src={arrowIcon} alt={'arrow icon'} />
                      <Box>{message.destination}</Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{ color: '#F3F9FF', borderBottom: '1px solid #182027' }}
                    align="right"
                  >
                    {message.sentAt}
                  </TableCell>
                  <TableCell
                    sx={{ color: '#F3F9FF', borderBottom: '1px solid #182027' }}
                    align="right"
                  >
                    {message.deliveryDuration}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: '#F3F9FF',
                      borderBottom: '1px solid #182027',
                    }}
                    align="right"
                  >
                    <Tooltip title={statusTooltip}>
                      <Typography
                        component="p"
                        textTransform="uppercase"
                        border={`1px solid ${statusBorderColor}`}
                        borderRadius="24px"
                        fontWeight={700}
                        padding="2px 12px"
                        display="inline-block"
                        width="110px"
                        textAlign="center"
                        fontSize="12px"
                      >
                        {message.status}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default Messages;
