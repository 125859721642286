import { Button, Box, MenuItem, MenuList, Select } from '@mui/material';
import { shorten2, WISP_DOCS, WISP_WEBSITE } from '../utils';
import { Link, useLocation } from 'react-router-dom';
import Blockies from 'react-blockies';
import copyIcon from '../icons/copy.svg';
import logo from '../icons/logo.svg';
import { ConnectKitButton } from 'connectkit';
import { useAccount, useDisconnect } from 'wagmi';
import { toast } from 'react-toastify';

const nav = [
  { name: 'Bridge', url: '/', target: '_self' },
  { name: 'Dashboard', url: '/dashboard', target: '_self' },
  { name: 'Docs', url: WISP_DOCS, target: '_blank' },
  { name: 'Get Görli Base ETH ', url: 'https://bridge.base.org/deposit', target: '_blank' },
  {
    name: 'Get Görli Optimism ETH',
    url: 'https://app.optimism.io/bridge/deposit',
    target: '_blank',
  },
];

const Header = () => {
  const { address } = useAccount();
  const { disconnect } = useDisconnect();

  const location = useLocation();

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(String(address));
    toast('Address copied');
  };

  return (
    <header>
      <Box>
        <MenuItem component={Link} to={WISP_WEBSITE} target={'_blank'}>
          <img width={138} height={32} src={logo} alt={'Logo'} />
        </MenuItem>
      </Box>
      <Box>
        <MenuList sx={{ display: 'flex', marginLeft: '40px' }}>
          {nav.map(item => {
            return (
              <MenuItem
                disableRipple
                selected={location.pathname === item.url}
                key={item.name}
                component={Link}
                to={item.url}
                target={item.target}
                sx={{
                  marginRight: '20px',
                  color: '#90AFCC !important',
                  '&.Mui-selected': {
                    background: 'none',
                    color: '#F3F9FF !important',
                  },
                  '&.Mui-selected:hover': {
                    background: 'none',
                  },
                }}
              >
                {item.name}
              </MenuItem>
            );
          })}
        </MenuList>
      </Box>
      <Box marginLeft={'auto'}>
        <Box>
          <ConnectKitButton.Custom>
            {({ isConnected, show, address }) => {
              if (isConnected) {
                return (
                  <Select
                    variant="standard"
                    disableUnderline
                    MenuProps={{
                      sx: {
                        '&& .Mui-selected': {
                          backgroundColor: 'transparent',
                        },
                      },
                      PaperProps: {
                        sx: {
                          color: '#F3F9FF',
                          borderRadius: '16px',
                          '& .MuiMenuItem-root': {
                            padding: 2,
                          },
                          background: '#071C58',
                          width: '290px',
                          marginTop: '20px',
                          marginLeft: '-15px',
                          position: 'relative',
                        },
                      },
                    }}
                    sx={{
                      color: '#F3F9FF',
                      borderRadius: '56px',
                      border: `1px solid #91AFCC`,
                      '.MuiSvgIcon-root ': {
                        fill: '#F3F9FF',
                      },
                      width: '224px',
                      cursor: 'pointer',
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': {
                        border: 0,
                      },
                      padding: '8px 18px',
                    }}
                    value={shorten2(address)}
                  >
                    <MenuItem value={shorten2(address)}>
                      <Box display={'flex'} alignItems={'center'}>
                        <Blockies
                          seed={shorten2(address)}
                          color={'#F3F9FF'}
                          className="ident-icon"
                        ></Blockies>
                        <p className="bold">{shorten2(address)}</p>
                      </Box>
                    </MenuItem>
                    <Box
                      position={'absolute'}
                      right={'20px'}
                      top={'25px'}
                      sx={{ cursor: 'pointer' }}
                      onClick={handleCopyAddress}
                    >
                      <img src={copyIcon} alt="Copy" />
                    </Box>
                    <Box padding={'0 18px'}>
                      <Button
                        onClick={() => disconnect()}
                        variant="contained"
                        fullWidth
                        sx={{
                          margin: '16px 0',
                          borderRadius: '100px',
                          height: '48px',
                          fontWeight: 700,
                        }}
                      >
                        Disconnect
                      </Button>
                    </Box>
                  </Select>
                );
              } else {
                return (
                  <Button
                    onClick={show}
                    variant="contained"
                    sx={{
                      borderRadius: '100px',
                      width: '192px',
                      height: '48px',
                      fontWeight: 800,
                    }}
                  >
                    Connect wallet
                  </Button>
                );
              }
            }}
          </ConnectKitButton.Custom>
        </Box>
      </Box>
    </header>
  );
};

export default Header;
