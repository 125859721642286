import Dashboard from './pages/Dashboard';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Bridge from './pages/Bridge';
import Message from './pages/Message';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Bridge />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/message/:msgHash" element={<Message />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
