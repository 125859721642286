import { Box, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface IPagination {
  page: number;
  totalPages: number;
  setPage: (page: number) => void;
}
const Pagination = ({ page, totalPages, setPage }: IPagination) => {
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      color={'#F3F9FF'}
      marginTop={'30px'}
      marginBottom={'40px'}
    >
      <IconButton disabled={page === 1} onClick={() => setPage(page - 1)}>
        <ArrowBackIcon style={{ fill: page === 1 ? '#91AFCC' : '#F3F9FF' }} />
      </IconButton>
      <Box
        component={'p'}
        fontWeight={'bold'}
        margin={'0 16px'}
      >{`Page ${page} of ${totalPages}`}</Box>
      <IconButton disabled={page === totalPages} onClick={() => setPage(page + 1)}>
        <ArrowForwardIcon style={{ fill: page === totalPages ? '#91AFCC' : '#F3F9FF' }} />
      </IconButton>
    </Box>
  );
};

export default Pagination;
