import ReactDOM from 'react-dom/client';
import { Provider } from './provider';
import App from './App';

import './styles/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider>
    <App />
  </Provider>,
);
