import Dialog from '@mui/material/Dialog';
import { Box, Button } from '@mui/material';
import errorIcon from '../../icons/info.svg';
import { useDisconnect } from 'wagmi';

export interface IWrongNetworkModal {
  open: boolean;
  onClose: any;
  switchNetwork: () => void;
}

const WrongNetwork = (props: IWrongNetworkModal) => {
  const { onClose, open, switchNetwork } = props;
  const { disconnect } = useDisconnect();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        style: {
          width: '512px',
          height: '455px',
          backgroundColor: '#071C58',
          boxShadow: 'none',
          borderRadius: '16px',
          textAlign: 'center',
        },
      }}
      sx={{
        backdropFilter: 'blur(8px)',
      }}
    >
      <Box padding={'24px'}>
        <Box margin={'30px auto'} textAlign={'center'}>
          <img src={errorIcon} alt="Error" />
        </Box>
        <Box marginBottom={'5px'} textAlign={'center'}>
          <h2 className="modal-title">Check your Network</h2>
        </Box>
        <p className="align-center steel-gray"> Please switch your network to continue</p>
        <Box display={'flex'} flexDirection={'column'}>
          <Button
            sx={{
              height: '56px',
              margin: '48px 0 24px',
              '&.Mui-disabled': {
                background: '#071C58',
                color: '#91AFCC',
              },
              borderRadius: '100px',
              fontWeight: 'bold',
            }}
            onClick={switchNetwork}
            variant="contained"
          >
            Switch network in wallet
          </Button>
          <Button
            sx={{
              fontWeight: 'bold',
              color: '#F3F9FF',
            }}
            onClick={() => disconnect()}
          >
            Disconnect wallet
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default WrongNetwork;
