import axios from 'axios';
import { API_ROOT_URL } from '../utils';

export default class API {
  static async getOverview() {
    const res = await axios.get(`${API_ROOT_URL}/overview`);
    return res.data;
  }

  static async getMessages(page: number, limit: number) {
    const res = await axios.get(`${API_ROOT_URL}/messages?page=${page}&limit=${limit}`);
    return res.data;
  }

  static async getMessage(id: string) {
    const res = await axios.get(`${API_ROOT_URL}/messages/${id}`);
    return res.data;
  }

  static async getLightClientUpdates(page: number, limit: number) {
    const res = await axios.get(`${API_ROOT_URL}/light-client-updates?page=${page}&limit=${limit}`);
    return res.data;
  }
}
