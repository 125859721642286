import { Box } from '@mui/material';
interface IDashboardCard {
  label: string;
  value: number | string;
}
const OverviewCard = (props: IDashboardCard) => {
  const { label, value } = props;
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      flexDirection={'column'}
      sx={{
        background: '#071332',
      }}
      flex={1}
      borderRadius={'12px'}
      paddingLeft={'24px'}
      height={'84px'}
    >
      <Box color={'#91AFCC'}>{label}</Box>
      <Box color={'#F3F9FF'} fontWeight={700}>
        {value}
      </Box>
    </Box>
  );
};

export default OverviewCard;
