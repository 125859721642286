import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Box, Typography, Container, Grid, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import copyIcon from '../icons/copy.svg';
import API from '../api';
import { MessageDetails } from '../interface';
import {
  calculateDeliveryDuration,
  GOERLI_EXPLORER,
  NETWORKS,
  setBorder,
  setStatus,
} from '../utils';
import moment from 'moment/moment';

const Message = () => {
  const { msgHash } = useParams();

  const [message, setMessage] = useState<MessageDetails | null>(null);

  useEffect(() => {
    if (msgHash) {
      requestMessage(msgHash);
    }
  }, [msgHash]);

  const requestMessage = async (msgHash: string) => {
    const res = await API.getMessage(msgHash);

    const {
      hash,
      sourceChainTxHash,
      destinationUserApplication,
      targetChainTxHash,
      l1BlockNumber,
      sourceChainTxTimestamp,
      sourceChainTxBlockNumber,
      payload,
      sourceChainId,
      targetChainTxTimestamp,
      user,
      l1ChainTxHash,
      targetChainTxBlockNumber,
      l1BlockTimestamp,
    } = res;

    // Determine message status
    const status = setStatus(targetChainTxHash, l1BlockNumber);

    // Set delivery duration
    const deliveryDuration = calculateDeliveryDuration(
      status,
      targetChainTxTimestamp,
      sourceChainTxTimestamp,
    );

    // Calculate time since send, L1 update and delivered
    const now = moment().unix();
    const timeSinceSendUnix = now - sourceChainTxTimestamp;
    const timeSinceSend = moment.duration(timeSinceSendUnix, 'seconds').humanize();
    const timeSinceL1updateUnix = now - l1BlockTimestamp;
    const timeSinceL1update = moment.duration(timeSinceL1updateUnix, 'seconds').humanize();
    const timeSinceDeliveryUnix = now - targetChainTxTimestamp;
    const timeSinceDelivery = moment.duration(timeSinceDeliveryUnix, 'seconds').humanize();

    const sourceNetwork = NETWORKS.find(network => network.chainId === sourceChainId);
    const destinationNetwork = NETWORKS.find(
      network => network.chainId && network.chainId !== sourceChainId,
    );

    const msg: MessageDetails = {
      status: status,
      messageId: hash,
      sourceTxHash: sourceChainTxHash,
      destinationUserApplication,
      payload,
      sender: user,
      deliveryDuration,
      sourceNetwork: sourceNetwork?.name || '',
      destinationNetwork: destinationNetwork?.name || '',
      l1BlockNumber: l1BlockNumber ? `${l1BlockNumber} (${timeSinceL1update} ago)` : '-',
      l1ChainTxHash: l1ChainTxHash,
      targetChainTxBlockNumber: targetChainTxBlockNumber
        ? `${targetChainTxBlockNumber} (${timeSinceDelivery} ago)`
        : '-',
      sourceExplorer: sourceNetwork?.explorerUrl || '',
      destinationExplorer: destinationNetwork?.explorerUrl || '',
      sourceChainTxBlockNumber: sourceChainTxBlockNumber
        ? `${sourceChainTxBlockNumber} (${timeSinceSend} ago)`
        : '-',
    };
    setMessage(msg);
  };

  const statusBorderColor = setBorder(message?.status || '');

  return (
    <Container maxWidth="lg">
      <Link
        to={'/dashboard'}
        style={{
          textDecoration: 'none',
          color: '#F3F9FF',
          marginBottom: '25px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ArrowBackIcon style={{ fill: '#F3F9FF', marginRight: '10px' }} />
        Back to Dashboard
      </Link>
      <Box display={'flex'} gap={'10px'} marginBottom={'45px'}>
        <p className="steel-gray">Message Details</p>
        <p className="white">{msgHash}</p>
      </Box>
      <Grid container spacing={2} marginBottom={'40px'}>
        {message ? (
          <>
            <Grid item xs={6} md={4} borderBottom={`1px solid #182027`} paddingBottom={'14px'}>
              <p className="white">Message ID</p>
            </Grid>
            <Grid item xs={6} md={8} borderBottom={`1px solid #182027`}>
              <p className={'f-14 white'}>{message.messageId}</p>
            </Grid>
            <Grid item xs={6} md={4} borderBottom={`1px solid #182027`} paddingBottom={'14px'}>
              <p className="white">Delivery Duration</p>
            </Grid>
            <Grid item xs={6} md={8} borderBottom={`1px solid #182027`}>
              <p className="white f-14">{message.deliveryDuration}</p>
            </Grid>
            <Grid item xs={6} md={4} borderBottom={`1px solid #182027`} paddingBottom={'14px'}>
              <p className="white">Status</p>
            </Grid>
            <Grid item xs={6} md={8} borderBottom={`1px solid #182027`}>
              <Typography
                color={'#F3F9FF'}
                component="p"
                textTransform="uppercase"
                border={`1px solid ${statusBorderColor}`}
                borderRadius="24px"
                fontWeight={700}
                padding="2px 12px"
                width={'110px'}
                textAlign={'center'}
                fontSize={'12px'}
              >
                {message.status}
              </Typography>
            </Grid>
            <Grid item xs={6} md={4} borderBottom={`1px solid #91AFCC`} paddingBottom={'14px'}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                height={'100%'}
                justifyContent={'space-between'}
              >
                <p className="white">Payload</p>
                {/*<p className="white">Decode</p>*/}
              </Box>
            </Grid>
            <Grid item xs={6} md={8} borderBottom={`1px solid #91AFCC`}>
              <Box
                minHeight={'105px'}
                border={`1px solid #546B82 `}
                borderRadius={'6px'}
                color={'#546B82'}
                sx={{ wordWrap: 'break-word' }}
                marginBottom={'15px'}
                padding={'10px'}
                fontSize={'12px'}
              >
                {message.payload}
              </Box>
            </Grid>
            <Grid item xs={6} md={4} borderBottom={`1px solid #182027`} paddingBottom={'14px'}>
              <p className="white">Network</p>
            </Grid>
            <Grid item xs={6} md={8} borderBottom={`1px solid #182027`}>
              <p className="white f-14">{message.sourceNetwork} Görli</p>
            </Grid>
            <Grid item xs={6} md={4} borderBottom={`1px solid #182027`} paddingBottom={'14px'}>
              <p className="white">Sender</p>
            </Grid>
            <Grid item xs={6} md={8} borderBottom={`1px solid #182027`}>
              <p className={'f-14 white'}>{message.sender}</p>
            </Grid>
            <Grid item xs={6} md={4} borderBottom={`1px solid #182027`} paddingBottom={'14px'}>
              <p className="white">Send Transaction Hash</p>
            </Grid>
            <Grid item xs={6} md={8} borderBottom={`1px solid #182027`}>
              <Link
                to={`${message.sourceExplorer}/tx/${message.sourceTxHash}`}
                target={'_blank'}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                }}
              >
                <p className="mr-10 light-blue f-14">{message.sourceTxHash}</p>
                <img width={20} src={copyIcon} alt="Copy icon" />
              </Link>
            </Grid>
            <Grid item xs={6} md={4} borderBottom={`1px solid #91AFCC`} paddingBottom={'14px'}>
              <p className="white">Send Block</p>
            </Grid>
            <Grid item xs={6} md={8} borderBottom={`1px solid #91AFCC`}>
              <p className="white f-14">{message.sourceChainTxBlockNumber}</p>
            </Grid>
            <Grid item xs={6} md={4} borderBottom={`1px solid #182027`} paddingBottom={'14px'}>
              <p className="white">Network</p>
            </Grid>
            <Grid item xs={6} md={8} borderBottom={`1px solid #182027`}>
              <p className="white f-14">Ethereum Görli</p>
            </Grid>
            <Grid item xs={6} md={4} borderBottom={`1px solid #182027`} paddingBottom={'14px'}>
              <p className="white">Message Anchor Transaction</p>
            </Grid>
            <Grid item xs={6} md={8} borderBottom={`1px solid #182027`}>
              {message.l1ChainTxHash ? (
                <Link
                  to={`${GOERLI_EXPLORER}/tx/${message.l1ChainTxHash}`}
                  target={'_blank'}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                  }}
                >
                  <p className={'mr-10 light-blue f-14'}>{message.l1ChainTxHash}</p>
                  <img width={20} src={copyIcon} alt="Copy icon" />
                </Link>
              ) : (
                <p className="white f-14">-</p>
              )}
            </Grid>
            <Grid item xs={6} md={4} borderBottom={`1px solid #91AFCC`} paddingBottom={'14px'}>
              <p className="white">Anchor Block</p>
            </Grid>
            <Grid item xs={6} md={8} borderBottom={`1px solid #91AFCC`}>
              <p className={'f-14 white'}>{message.l1BlockNumber}</p>
            </Grid>
            <Grid item xs={6} md={4} borderBottom={`1px solid #182027`} paddingBottom={'14px'}>
              <p className="white">Network</p>
            </Grid>
            <Grid item xs={6} md={8} borderBottom={`1px solid #182027`}>
              <p className="white f-14">{message.destinationNetwork} Görli</p>
            </Grid>
            <Grid item xs={6} md={4} borderBottom={`1px solid  #182027`} paddingBottom={'14px'}>
              <p className="white">Receiver</p>
            </Grid>
            <Grid item xs={6} md={8} borderBottom={`1px solid  #182027`}>
              <p className="white f-14">{message.destinationUserApplication}</p>
            </Grid>
            <Grid item xs={6} md={4} borderBottom={`1px solid #91AFCC`} paddingBottom={'14px'}>
              <p className="white">Delivery Block</p>
            </Grid>
            <Grid item xs={6} md={8} borderBottom={`1px solid #91AFCC`}>
              <p className="white f-14">{message.targetChainTxBlockNumber}</p>
            </Grid>
          </>
        ) : (
          <Box margin={'20px auto'}>
            <CircularProgress thickness={3} size={70} />
          </Box>
        )}
      </Grid>
    </Container>
  );
};

export default Message;
