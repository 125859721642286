import Dialog from '@mui/material/Dialog';
import { Box, Button } from '@mui/material';
import closeIcon from '../../icons/close.svg';
import Divider from '@mui/material/Divider';
import { Network } from '../../interface';

export interface ITransactionConfirmationModal {
  open: boolean;
  onClose: any;
  sourceNetwork: Network;
  destinationNetwork: Network;
  amount: string;
  handleDeposit: any;
}

const TransactionModal = (props: ITransactionConfirmationModal) => {
  const { onClose, open, sourceNetwork, destinationNetwork, amount, handleDeposit } = props;
  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        style: {
          width: '512px',
          backgroundColor: '#071C58',
          boxShadow: 'none',
          borderRadius: '16px',
        },
      }}
      sx={{
        backdropFilter: 'blur(8px)',
      }}
    >
      <Box padding={'24px'}>
        <Box display={'flex'} justifyContent={'space-between'} marginBottom={'48px'}>
          <h2 className="modal-title">Confirm Transaction</h2>
          <Box onClick={onClose} sx={{ cursor: 'pointer' }}>
            <img src={closeIcon} alt="Close" />
          </Box>
        </Box>
        <Box marginBottom={'24px'} display={'flex'}>
          <Box display={'flex'} flexDirection={'column'} marginRight={15}>
            <p className="steel-gray f-14 mb-8">From</p>
            <Box display={'flex'} alignItems={'center'}>
              <img src={sourceNetwork.icon} alt={`${sourceNetwork.name} logo`} />
              <p className="white ml-13"> {sourceNetwork.name}</p>
            </Box>
          </Box>
          <Box display={'flex'} flexDirection={'column'}>
            <p className="steel-gray f-14 mb-8">To</p>
            <Box display={'flex'} alignItems={'center'}>
              <img src={destinationNetwork.icon} alt={`${destinationNetwork.name} logo`} />
              <p className="white ml-13"> {destinationNetwork.name}</p>
            </Box>
          </Box>
        </Box>
        <Box marginBottom={'24px'}>
          <p className="steel-gray f-14 mb-8"> Destination Address</p>
          <p className="white f-14"> {destinationNetwork.bridgeContract}</p>
        </Box>
        <Box marginBottom={'24px'}>
          <p className="steel-gray f-14"> Token Amount</p>
          <p className="white f-14"> {amount} ETH</p>
        </Box>
        <Divider color={'#91AFCC'} />
        <Button
          fullWidth
          sx={{
            height: '56px',
            marginTop: '48px',
            marginBottom: '24px',
            '&.Mui-disabled': {
              background: '#071C58',
              color: '#91AFCC',
            },
            borderRadius: '100px',
            fontWeight: 'bold',
          }}
          onClick={handleDeposit}
          variant="contained"
        >
          Confirm transaction
        </Button>
      </Box>
    </Dialog>
  );
};

export default TransactionModal;
