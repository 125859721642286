import { Box } from '@mui/material';
import { DisplayOverview } from '../../interface';
import OverviewCard from './OverviewCard';

interface IOverview {
  overview: DisplayOverview;
  error: boolean;
}
const Overview = ({ overview, error }: IOverview) => {
  return (
    <>
      {error ? (
        <Box margin={'20px'} textAlign={'center'}>
          <p className="red">Something went wrong while fetching the overview...</p>
        </Box>
      ) : (
        <>
          <Box display={'flex'} gap={'16px'} marginBottom={'72px'}>
            <OverviewCard label={'Total Messages Relayed'} value={overview.totalMessages} />
            <OverviewCard
              label={'Estimated Delivery Duration'}
              value={overview.averageDeliveryDuration}
            />
            <OverviewCard label={'Avg. Wisp Message Cost'} value={overview.averageCost} />
          </Box>
          <Box>
            <h2 className="page-title">Explore Wisp Messages</h2>
          </Box>
        </>
      )}
    </>
  );
};

export default Overview;
