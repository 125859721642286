import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { WISP_FAQ } from '../utils';

const InfoBanner = () => {
  return (
    <Box color={'#91AFCC'} display={'flex'} justifyContent={'center'}>
      <p className="f-14 steel-gray">
        <strong>Wisp</strong> is a decentralized, zk-based communication protocol, enabling
        permissionless, multi-chain applications. This app is only a demo bridge on top of Wisp.
      </p>
      <Link style={{ display: 'contents' }} target={'_blank'} to={WISP_FAQ}>
        <strong>
          <p className="f-14 ml-13 steel-gray underline">Read More</p>
        </strong>
      </Link>
    </Box>
  );
};

export default InfoBanner;
