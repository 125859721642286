import React, { Dispatch, SetStateAction } from 'react';
import { useState, useEffect } from 'react';
import { NETWORKS } from '../utils';
import { Network } from '../interface';
import { createClient, useAccount, useNetwork, WagmiConfig } from 'wagmi';
import { baseGoerli, optimismGoerli } from 'wagmi/chains';
import { ConnectKitProvider, getDefaultClient } from 'connectkit';

const contextInitialValue = {
  destinationNetwork: NETWORKS[0],
  sourceNetwork: NETWORKS[0],
  connectModal: false,
  showConnectModal: (() => {}) as Dispatch<SetStateAction<boolean>>,
  setSourceNetwork: (() => {}) as Dispatch<SetStateAction<Network>>,
  setDestinationNetwork: (() => {}) as Dispatch<SetStateAction<Network>>,
  wrongNetworkModal: false,
  showWrongNetworkModal: (() => {}) as Dispatch<SetStateAction<boolean>>,
};

const client = createClient(
  getDefaultClient({
    appName: 'Wisp',
    chains: [optimismGoerli, baseGoerli],
  }),
);

interface IGlobalProps {
  children: React.ReactNode;
}

export const AppContext = React.createContext(contextInitialValue);

export const Provider = ({ children }: IGlobalProps) => {
  const { isConnected } = useAccount();
  const { chain } = useNetwork();

  const [connectModal, showConnectModal] = useState(false);
  const [sourceNetwork, setSourceNetwork] = useState<Network>({} as Network);
  const [destinationNetwork, setDestinationNetwork] = useState<Network>({} as Network);
  const [wrongNetworkModal, showWrongNetworkModal] = useState(false);

  // Set networks when provider is present
  useEffect(() => {
    if (isConnected) {
      NETWORKS.forEach(n => {
        if (n.chainId === chain?.id) {
          setSourceNetwork(n);
        } else {
          setDestinationNetwork(n);
        }
      });
    } else {
      setSourceNetwork({} as Network);
      setDestinationNetwork({} as Network);
    }
  }, [isConnected, chain]);

  const contextValue = {
    destinationNetwork,
    sourceNetwork,
    connectModal,
    wrongNetworkModal,
    showWrongNetworkModal,
    showConnectModal,
    setSourceNetwork,
    setDestinationNetwork,
  };
  return (
    <AppContext.Provider value={contextValue}>
      <WagmiConfig client={client}>
        <ConnectKitProvider
          customTheme={{
            '--ck-overlay-backdrop-filter': 'blur(8px)',
            '--ck-border-radius': '16px',
            '--ck-body-background': '#071C58',
            '--ck-primary-button-background': '#010613',
            '--ck-primary-button-hover-background': '#071332',
          }}
          options={{ initialChainId: 0 }}
        >
          {children}
        </ConnectKitProvider>
      </WagmiConfig>
    </AppContext.Provider>
  );
};
