import { Box, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { DisplayMessage, DisplayLightClientUpdate, DisplayOverview } from '../interface';
import API from '../api';
import Messages from '../components/Dashboard/Messages';
import LightClientUpdates from '../components/Dashboard/LightClientUpdates';
import { calculateDeliveryDuration, formatStr, NETWORKS, setStatus } from '../utils/index';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '../components/Dashboard/Pagination';
import Overview from '../components/Dashboard/Overview';
import { ethers } from 'ethers';

const ITEMS_PER_PAGE = 5;

const Dashboard = () => {
  const [overview, setOverview] = useState<DisplayOverview>({} as DisplayOverview);
  const [overviewLoading, setOverviewLoading] = useState(false);
  const [overviewError, setOverviewError] = useState(false);

  const [messages, setMessages] = useState<DisplayMessage[]>([]);
  const [messagesPage, setMessagesPage] = useState(1);
  const [totalMessagePages, setTotalMessagePages] = useState(0);
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [messagesError, setMessagesError] = useState(false);

  const [updates, setUpdates] = useState<DisplayLightClientUpdate[]>([]);
  const [updatesPage, setUpdatesPage] = useState(1);
  const [totalUpdatePages, setTotalUpdatePages] = useState(0);
  const [updatesLoading, setUpdatesLoading] = useState(false);
  const [updatesError, setUpdatesError] = useState(false);

  useEffect(() => {
    requestOverview();
  }, []);

  useEffect(() => {
    requestMessages();
  }, [messagesPage]);

  useEffect(() => {
    requestLightClientUpdates();
  }, [updatesPage]);

  const requestOverview = async () => {
    setOverviewLoading(true);
    try {
      const data: any = await API.getOverview();
      const { totalMessages, averageDeliveryDuration, averageCost } = data;

      const displayOverview: DisplayOverview = {
        totalMessages: totalMessages,
        averageDeliveryDuration:
          moment.duration(averageDeliveryDuration, 'seconds').humanize() || '',
        averageCost: `${formatStr(ethers.utils.formatEther(averageCost.toString()))} ETH`,
      };

      setOverview(displayOverview);
    } catch (error) {
      console.log(error);
      setOverviewError(true);
    }
    setOverviewLoading(false);
  };

  const requestMessages = async () => {
    setMessagesLoading(true);
    try {
      const data = await API.getMessages(messagesPage, ITEMS_PER_PAGE);
      if (data.messages.length) {
        const displayMessages: DisplayMessage[] = [];
        data.messages.forEach((message: any) => {
          const {
            targetChainTxHash,
            sourceChainTxHash,
            sourceChainTxTimestamp,
            targetChainTxTimestamp,
            sourceChainId,
            targetChainId,
            l1BlockNumber,
            hash,
          } = message;

          const source = NETWORKS.find(network => network.chainId === sourceChainId);
          const destination = NETWORKS.find(network => network.chainId === targetChainId);

          // Calculate "Sent At"
          let sentAt = '';
          const txDate = moment.unix(sourceChainTxTimestamp).format('YYYY-MM-DD');
          const isTxToday = moment().isSame(txDate, 'day');
          if (isTxToday) {
            sentAt = moment.unix(sourceChainTxTimestamp).format('hh:mm A');
          } else {
            const now = moment().unix();
            const diff = now - sourceChainTxTimestamp;
            sentAt = `${moment.duration(diff, 'seconds').humanize()} ago`;
          }

          // Determine message status
          const status = setStatus(targetChainTxHash, l1BlockNumber);

          // Set delivery duration
          const deliveryDuration = calculateDeliveryDuration(
            status,
            targetChainTxTimestamp,
            sourceChainTxTimestamp,
          );

          const msg: DisplayMessage = {
            messageId: hash,
            explorerUrl: `${source!.explorerUrl}`,
            source: source?.name || '',
            destination: destination?.name || '',
            sentAt: sentAt,
            status: status,
            deliveryDuration: deliveryDuration,
            sourceTxHash: sourceChainTxHash,
          };
          displayMessages.push(msg);
        });

        setMessages(displayMessages);
      }
      if (data.totalPages) {
        setTotalMessagePages(data.totalPages);
      }
    } catch (error) {
      console.log(error);
      setMessagesError(true);
    }
    setMessagesLoading(false);
  };

  const requestLightClientUpdates = async () => {
    setUpdatesLoading(true);
    try {
      const data = await API.getLightClientUpdates(updatesPage, ITEMS_PER_PAGE);
      const displayLightClientUpdates: DisplayLightClientUpdate[] = [];
      if (data.updates.length) {
        data.updates.forEach((update: any) => {
          const { transactionHash, transactionTimestamp, chainId, l1BlockNumber } = update;

          const rollup = NETWORKS.find(network => network.chainId === chainId);
          const updatedDiff = moment().unix() - transactionTimestamp;
          const updatedBefore = moment.duration(updatedDiff, 'seconds').humanize();

          const _update: DisplayLightClientUpdate = {
            rollup: rollup?.name || '',
            logo: rollup?.icon || '',
            rollupTxHash: transactionHash,
            l1BlockNumber: `${l1BlockNumber} (${updatedBefore} ago)`,
            explorerUrl: `${rollup!.explorerUrl}`,
          };
          displayLightClientUpdates.push(_update);
        });
        setUpdates(displayLightClientUpdates);
      }
      if (data.totalPages) {
        setTotalUpdatePages(data.totalPages);
      }
    } catch (error) {
      console.log(error);
      setUpdatesError(true);
    }
    setUpdatesLoading(false);
  };

  return (
    <>
      <Container maxWidth="lg">
        <>
          <Box marginBottom={'40px'}>
            <h2 className="page-title">Wisp stats</h2>
          </Box>
          {overviewLoading ? (
            <Box textAlign={'center'} margin="20px">
              <CircularProgress thickness={3} size={70} />
            </Box>
          ) : (
            <Overview overview={overview} error={overviewError} />
          )}
          <Box minHeight={'346px'}>
            {messagesLoading ? (
              <Box textAlign={'center'} margin="20px">
                <CircularProgress thickness={3} size={70} />
              </Box>
            ) : (
              <Messages messages={messages} error={messagesError} />
            )}
          </Box>
          {messages.length ? (
            <Pagination
              page={messagesPage}
              setPage={setMessagesPage}
              totalPages={totalMessagePages}
            />
          ) : null}
          <Box>
            <h2 className="page-title">Recent Light Client Updates</h2>
            <p className="white f-14">
              * Updates are produced on-demand, based on message activity
            </p>
          </Box>
          <Box minHeight={'346px'}>
            {updatesLoading ? (
              <Box textAlign={'center'} margin="20px">
                <CircularProgress thickness={3} size={70} />
              </Box>
            ) : (
              <LightClientUpdates updates={updates} error={updatesError} />
            )}
          </Box>
          {updates.length ? (
            <Pagination page={updatesPage} setPage={setUpdatesPage} totalPages={totalUpdatePages} />
          ) : null}
        </>
      </Container>
    </>
  );
};

export default Dashboard;
